import ReferralCard from "~/components/account/referralCard.vue";
export default defineComponent({
  components: {
    ReferralCard: ReferralCard
  },
  props: {
    idtoken: {
      type: Object,
      "default": function _default() {
        return {
          payload: {}
        };
      }
    },
    dev: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      copied: false
    };
  },
  computed: {
    refLink: function refLink() {
      var dev = this.dev;
      var refCode = this.refCode;
      return dev ? "https://bob.tikr.com/register?ref=".concat(refCode) : "https://app.tikr.com/register?ref=".concat(refCode);
    },
    refCode: function refCode() {
      return this.idtoken.payload["custom:refCode"];
    } // tooltipMsg() {
    //   const copied = this.copied
    //   return copied
    //     ? this.I18nFn("Link copied to clipboard")
    //     : this.I18nFn("Click to copy to clipboard")
    // },
    // firstReferralParagraph() {
    //   return `Provide friends immediate TIKR.com access and 20% off a 12 month subscription using your personal referral link below`
    // },
    // referralOffer() {
    //   return `referral offer here`
    // },
    // explainReferral() {
    //   return `For each user you refer who purchases a subscription before June 1st you will be credited 20% of their subscription`
    // },
    // finePrint() {
    //   return [
    //     `20% off subscription valid until June 1 2020`,
    //     `Referral credits distributed to referrer within 30 days of referee subscription. Total credit limited to the subscription you have purchased. Offer valid until June 1 2020`,
    //   ]
    // },
  }
});